import React from "react"
import styled from "styled-components"

import { HSection, Grid, ValueProp, Cta } from "components/common"

import { device, featureColors, featureGlass } from "utils"

const FinancesSageIntacct = ({
  hat,
  title,
  subtitle,
  valueProps,
  media,
  ctas,
}) => {
  const renderGrid = () => (
    <Grid
      width="100%"
      list={valueProps}
      component={(vp) => (
        <ValueProp
          orientation="horizontal"
          {...vp}
          media={{ desktop: vp.icon }}
        />
      )}
    />
  )

  const renderCta = () => <Cta ctas={ctas} />

  return (
    <StyledFinancesSageIntacct>
      <HSection
        hat={hat}
        title={title}
        titleAs="h2"
        subtitle={subtitle}
        media={media}
        grid={renderGrid}
        cta={renderCta}
        ctaOrder="4"
        mobileCtaOrder="4"
        mobileFull
      />
    </StyledFinancesSageIntacct>
  )
}

const StyledFinancesSageIntacct = styled.div`
  --tg-hat-color: ${featureColors.finances};
  --section-max-width: 1080px;

  ${featureGlass};

  @media ${device.laptop} {
    --tg-max-width: 380px;
  }

  .gridWrap {
    .vp_contentWrap {
      grid-template-columns: 22px 1fr;
      .image {
        padding-top: 5px;
      }

      .subtitle {
        font-weight: normal;
        line-height: var(--sp-32);
      }
    }
  }
`

export default FinancesSageIntacct
