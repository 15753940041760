import React from "react"
import styled from "styled-components"

import { device, featureColors, featureGlass } from "utils"

import { VerticalSection, ValueProp, Grid } from "components/common"

const FinancesStayInSync = ({ hat, title, subtitle, valueProps, media }) => {
  const renderGrid = () => (
    <Grid
      col={2}
      gap={64}
      align="center"
      width="auto"
      list={valueProps}
      component={(vp) => <ValueProp orientation="horizontal" {...vp} />}
    />
  )

  return (
    <StyledFinancesStayInSync>
      <VerticalSection
        hat={hat}
        titleAs="h2"
        title={title}
        subtitle={subtitle}
        media={media}
        mobileTgOrder="1"
        grid={renderGrid}
        mobileFull
      />
    </StyledFinancesStayInSync>
  )
}

const StyledFinancesStayInSync = styled.div`
  --tg-hat-color: ${featureColors.finances};
  ${featureGlass};

  @media ${device.laptop} {
    --tg-max-width: 780px;
  }

  .gridWrap {
    margin-top: var(--sp-32);
    .vp_contentWrap {
      grid-template-columns: 22px auto;
      .image {
        padding-top: 5px;
      }

      .subtitle {
        font-weight: normal;
        line-height: var(--sp-32);
      }
    }
  }
`

export default FinancesStayInSync
