import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import { Layout, Seo, Nav } from "components/common"
import { useContentfulSection, useMeta } from "utils"

import {
  FinancesHero,
  FinancesQuickbooks,
  FinancesStayInSync,
  FinancesSageIntacct,
} from "src/sections/features/finances"

import ExploreFeatures from "src/sections/features/ExploreFeatures"
import { CtaGetDemo } from "components/cta"

const FinancesPage = ({ data: { finances } }) => {
  const {
    finances_hero,
    finances_stay_in_sync,
    finances_quickbooks,
    explore_more_features,
    finances_sage_intacct,
    cta_get_demo,
  } = useContentfulSection(finances.sections)

  const meta = useMeta(finances)

  return (
    <Layout>
      <Seo {...meta} />
      <Wrap>
        <Nav />
        <FinancesHero {...finances_hero} />
        <FinancesStayInSync {...finances_stay_in_sync} />
        <FinancesQuickbooks {...finances_quickbooks} />
        <FinancesSageIntacct {...finances_sage_intacct} />

        <ExploreFeatures {...explore_more_features} />
        <CtaGetDemo {...cta_get_demo} />
      </Wrap>
    </Layout>
  )
}

const Wrap = styled.div``

export const query = graphql`
  {
    finances: contentfulPage(pageId: { eq: "finances" }) {
      ...Page
    }
  }
`

export default FinancesPage
